import React from "react";

import "./KontaktPage.css";
import { Link } from "react-router-dom";

const KontaktPage = () => {
	return (
		<div className="kontakt-container">
			<h3>KONTAKT</h3>
			<p>ARHITEKTURA PRODUKT</p>
			<p>biro za projektovanje i inženjering</p>
			<p>Riste Vukanovića 1   11000 Beograd</p>
			<p>WEB <Link to="www.arhitekturaprodukt.rs">www.arhitekturaprodukt.rs</Link></p>
			<p>MAIL <Link to="mailto:office@arhitekturaprodukt.rs">office@arhitekturaprodukt.rs</Link></p>
			<p>TEL +381 (0)64 613 60 15</p>
		</div>
	);
};

export default KontaktPage;