import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomePage from "./Containers/Pages/HomePage";
import ProjektiPage from "./Containers/Pages/ProjektiPage";
import ProjekatPage from "./Containers/Pages/ProjekatPage";
import ProfilePage from "./Components/ProfilPage";
import KontaktPage from "./Components/KontaktPage";
import NotFoundPage from "./Components/NotFoundPage";
import Header from "./Components/Header";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
		<Header />
	  	<Switch>
		  <Route path="/" component={HomePage} exact />
		  <Route path="/profil" component={ProfilePage} exact />
		  <Route path="/projekti" component={ProjektiPage} exact />
		  <Route path="/projekti/:projekat?" component={ProjekatPage} exact />
		  <Route path="/kontakt" component={KontaktPage} exact />
		  <Route component={NotFoundPage} />
		</Switch>
	  </BrowserRouter>
    </div>
  );
}

export default App;
