export default {
	izgradnja_stambenog_objekta_zvezdara: {
		projekat: "Izgradnja stambenog objekta",
		velicina: "2375",
		investitor: "BG Urban LUX doo",
		lokacija: "Zvezdara, Beograd, SRB",
		godina: 2019,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg"]
	},
	rekonstrukcija_poslovnog_prostora_stari_grad: {
		projekat: "Rekonstrukcija poslovnog prostora",
		velicina: "380",
		investitor: "Privilege Suites",
		lokacija: "Stari grad, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "11.jpg", "12.jpg", "13.jpg", "14.jpg", 
			"16.jpg", "18.jpg", "20.jpg", "22.jpg", "23.jpg", "24.jpg", 
			"25.jpg"]
	},
	izgradnja_stambenog_objekta_vracar_vajferta: {
		projekat: "Izgradnja stambenog objekta",
		velicina: "1840",
		investitor: "Blok Komplex doo",
		lokacija: "Vračar, Beograd, SRB",
		godina: 2018,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg"]
	},
	izgradnja_stambeno_poslovnog_objekta_stari_grad: {
		projekat: "Izgradnja stambeno-poslovnog objekta",
		velicina: "1495",
		investitor: "BG Urban LUX doo",
		lokacija: "Stari grad, Beograd, SRB",
		godina: 2018,
		img: ["01.jpg", "02.jpg", "03.jpg"]
	},
	uredjenje_partera_vozdovac: {
		projekat: "Uređenje partera",
		velicina: "80",
		investitor: "Privatni",
		lokacija: "Voždovac, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg"]
	},
	rekonstrukcija_stana_novi_beograd: {
		projekat: "Rekonstrukcija stana",
		velicina: "31",
		investitor: "Privatni",
		lokacija: "Novi Beograd, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	rekonstrukcija_sportske_hale_pancevo: {
		projekat: "Rekonstrukcija sportske hale",
		velicina: "2760",
		investitor: 'JKP "Mladost" Pančevo',
		lokacija: "Pančevo, SRB",
		godina: 2018,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg", "06.jpg"]
	},
	rekonstrukcija_objekta_kovin: {
		projekat: "Rekonstrukcija objekta - Odeljenje za intenzivan tretman psihotičnih poremećaja",
		velicina: "1370",
		investitor: 'Specijalna bolnica za psihijatrijske bolesti "Kovin"',
		lokacija: "Kovin, SRB",
		godina: 2018,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg"]
	},
	rekonstrukcija_porodicne_kuce_krcedin: {
		projekat: "Rekonstrukcija porodične kuće",
		velicina: "170",
		investitor: "Privatni",
		lokacija: "Krčedin, SRB",
		godina: 2018,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg"]
	},
	izgradnja_porodicne_kuce_miljakovac: {
		projekat: "Izgradnja porodične kuće",
		velicina: "550",
		investitor: "Privatni",
		lokacija: "Miljakovačka šuma, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg"]
	},
	rekonstrukcija_porodicne_kuce_slankamen: {
		projekat: "Rekonstrukcija porodične kuće",
		velicina: "160",
		investitor: "Privatni",
		lokacija: "Slankamen, SRB",
		godina: 2017,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg"]
	},
	enterijer_stana_novi_beograd: {
		projekat: "Enterijer stana",
		velicina: "59",
		investitor: "Privatni",
		lokacija: "Novi Beograd, Beograd, SRB",
		godina: 2015,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	enterijer_stana_vozdovac: {
		projekat: "Enterijer stana",
		velicina: "70",
		investitor: "Privatni",
		lokacija: "Voždovac, Beograd, SRB",
		godina: 2014,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	enterijer_stana_zvezdara: {
		projekat: "Enterijer stana",
		velicina: "100",
		investitor: "Privatni",
		lokacija: "Zvezdara, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	enterijer_stana_dravlje: {
		projekat: "Enterijer stana",
		velicina: "58",
		investitor: "Privatni",
		lokacija: "Dravlje, Ljubljana, SLO",
		godina: 2014,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	enterijer_stana_novi_beograd_2: {
		projekat: "Enterijer stana",
		velicina: "90",
		investitor: "Privatni",
		lokacija: "Novi Beograd, Beograd, SRB",
		godina: 2015,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg"]
	},
	enterijer_stana_vracar: {
		projekat: "Enterijer stana",
		velicina: "120",
		investitor: "Privatni",
		lokacija: "Vračar, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg", "06.jpg"]
	},
	izgradnja_objekta_staracki_dom: {
		projekat: "Izgradnja objekta - starački dom",
		velicina: "5200",
		investitor: "Privatni",
		lokacija: "Zvezdara, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg"]
	},
	uredjenje_priobalja_reke_tamis: {
		projekat: "Uređenje priobalja reke Tamiš u Pančevu",
		velicina: "193000",
		investitor: "Grad Pančevo",
		lokacija: "Pančevo, SRB",
		godina: 2019,
		img: ["01.jpg", "02.jpg", "03.jpg", "05.jpg", "06.jpg", "07.jpg",
			"08.jpg", "09.jpg", "10.jpg", "11.jpg", "12.jpg", "13.jpg", "14.jpg"]
	},
	izgradnja_stambenog_objekta_zvezdara_2: {
		projekat: "Izgradnja stambenog objekta",
		velicina: "435",
		investitor: "Privatni",
		lokacija: "Zvezdara, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg"]
	},
	izgradnja_krovne_terase: {
		projekat: "Izgradnja krovne terase",
		velicina: "20",
		investitor: "Privatni",
		lokacija: "Vračar, Beograd, SRB",
		godina: 2016,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg", "06.jpg"]
	},
	rekonstrukcija_trga_pancevo: {
		projekat: "Rekonstrukcija trga Kralj Petar I u Pančevu",
		velicina: "29800",
		investitor: "Grad Pančevo",
		lokacija: "Pančevo, SRB",
		godina: 2019,
		img: ["01.jpg", "02.jpg", "03.jpg", "04.jpg", "05.jpg"]
	}
};