import React, { useState, useRef, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./Header.css";
import miniLogo from "../img/icons/minilogo.jpg";
import mainNavImage from "../img/icons/menu.png";
import close from "../img/icons/close.png";

const Header = props => {
	const { location: { pathname } } = props;
	const renderHeader = pathname !== "/" && pathname !== "";

	const [menuOpen, setMenuOpen] = useState(false);

	const mainNav = useRef(null);
	const menuButton = useRef(null);

	const openCloseMenu = () => {
		setMenuOpen(prevState => {
			return !prevState;
		})
	};

	useEffect(() => {
		if (menuOpen && mainNav.current) {
			mainNav.current.className = "main-nav show-nav";
			menuButton.current.src = close;
		}
		if (!menuOpen && mainNav.current) {
			mainNav.current.className = "main-nav";
			menuButton.current.src = mainNavImage;
		}
	}, [menuOpen]);

	useEffect(() => {
		if (!renderHeader && menuOpen) {
			setMenuOpen(false);
		}
	}, [renderHeader]);

	useEffect(() => {
		window.addEventListener("click", event => {
			if ((mainNav.current && !mainNav.current.contains(event.target) && 
				menuButton.current && !menuButton.current.contains(event.target)) ||
				event.target.className.includes("nav-link")) {
				setMenuOpen(false);
			}
		})
	}, [mainNav]);

	return renderHeader ? (
		<div className="header">
			<img src={miniLogo} alt="logo" className="mini-logo" />
			<div ref={mainNav} className="main-nav">
				<NavLink to="/" className="nav-link">početna</NavLink>
				<NavLink to="/profil" className="nav-link" activeClassName="active-nav-link">profil</NavLink>
				<NavLink to="/projekti" className="nav-link" activeClassName="active-nav-link">projekti</NavLink>
				<NavLink to="/kontakt" className="nav-link" activeClassName="active-nav-link">kontakt</NavLink>
			</div>
			<div className="menu-button" onClick={openCloseMenu}>
				<img ref={menuButton} src={mainNavImage} alt="menu" />
			</div>
		</div>
	) : null;
};

export default withRouter(Header);