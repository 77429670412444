import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import loader from "../../img/icons/loader2.gif";

const ProjectContainer = props => {
	const { project } = props;

	const [loading, setLoading] = useState(true);

	const projectImage = useRef(null);

	const positionImage = () => {
		const image = projectImage.current;
		const width = image.width;
		const height = image.height;
		if (height / width <= 0.556) {
			image.style.height = "100%";
		}
		if (height / width > 0.556) {
			image.style.width = "100%";
		}
		setLoading(false);
	};

	return (
			<div className="project-element">
				<Link to={`/projekti/${project.projectLocation}`} className="project-link">
					<div className="image-overlay">
						<p className="naziv">{project.projekat}</p>
						<p className="mesto-godina">{project.lokacija}, {project.godina}.</p>
					</div>
					<img src={`../../img/${project.projectLocation}/01.jpg`} 
						alt={`${project.projekat} ${project.lokacija}` } 
						onLoad={positionImage}
						ref={projectImage}
					/>
					{loading && <div className="loader-container"><img src={loader} alt="loader" className="loader" /></div>}
				</Link>
			</div>
	);
};

export default ProjectContainer;