import React from "react";

import "./ProfilPage.css";

import filip from "../img/icons/filip.jpg";
import maja from "../img/icons/maja.jpg";

const ProfilPage = () => {
	return (
		<div className="profil-container">
			<h3>PROFIL</h3>
			<p>ARHITEKTURA PRODUKT  je mlad, inovativan arhitektonski studio sa sedištem u Beogradu. 
			   Osnovan je 2015. godine, u Beogradu. Osnivač je arhitekta Filip Zumberović.</p>
			<p>Rad studija je vođen jedinstvenim mogućnostima i ograničenjima specifičnih projekata 
			   koji se razvijaju kroz bliske odnose sa klijentima.</p>
			<p>Sa svakim projektom istražujemo nove načine integrisanja ideje s programskom i funkcionalnom 
			   suštinom zadatka. Umesto nametanja stila na različitim lokacijama, jedinstveni karakter programa 
			   i mesta postaje polazna tačka za arhitektonsku ideju.</p>
			<p>Od malih intervencija u postojećim prostorima, preko izgradnje novih objekata do urbanističkih 
			   rešenja, svi naši projekti dobijaju istu brigu i pažnju.</p>
			<h3>OD IDEJE DO REALIZACIJE</h3>
			<p>Počev od analize lokacije, preko ideje, sa timom naših stručnih saradnika, obezbeđujemo kompletnu 
			   uslugu konsaltinga, projektovanja i praćenja realizacije projekta.</p>
			<h3>ČLANOVI</h3>
			<div className="clanovi">
				<div className="clan-container">
					<img src={filip} alt="Filip Zumberović" />
					<p>Filip Zumberović</p>
				</div>
				<div className="clan-container">
					<img src={maja} alt="Maja Jovanović" />
					<p>Maja Jovanović</p>
				</div>
			</div>
		</div>
	);
};

export default ProfilPage;