import React, { useState, useEffect } from "react";
import projectMapping from "./mappings/projectMapping";
import loader from "../../img/icons/loader2.gif";
import "./ProjekatPage.css";
import { Redirect } from "react-router-dom";

const ProjekatPage = props => {
	const { history, match: { params: { projekat } } } = props;
	const project = projectMapping[projekat];

	const imagesArrayLength = !!project ? project.img.length : 0;
	

	const [loadingImagesCount, setLoadingImagesCount] = useState(imagesArrayLength);
	const [sliderCounter, setSliderCounter] = useState(0);
	const [createDots, setCreateDotts] = useState(false);

	const refCollection = {};
	const dotsRefCollection = {};

	const createImageSlider = () => {
		return project.img.map(imgName => {
			return `../../img/${projekat}/${imgName}`;
		}).map((img, index) => {
			if (!index) {
				return <div className="image-container" 
							style={{left: 0}}
							ref={instance => refCollection[index] = instance}
							key={index}
						>
							<img src={img} alt={projekat} 
								onLoad={() => {
									setLoadingImagesCount(prevCount => prevCount - 1);
								}}
							/>
						</div>
			}
			return <div className="image-container" 
						style={{left: "100%"}}
						ref={instance => refCollection[index] = instance}
						key={index}
					>
						<img src={img} alt={projekat} 
							onLoad={() => {
								setLoadingImagesCount(prevCount => prevCount - 1);
							}}
						/>
					</div>
		});
	};

	const createSliderDotts = () => {
		return (
			<div className="dots-container">
				{project.img.map((img, index) => {
					return <div className="dot" 
								key={index} 
								ref={instance => dotsRefCollection[index] = instance}
								onClick={() => {setSliderCounter(index)}}
							></div>
				})}
			</div>
		);
	};

	const colorActiveDot = () => {
		Object.keys(dotsRefCollection).forEach(index => {
			if (sliderCounter == index && dotsRefCollection[index]) {
				dotsRefCollection[index].style.background = "rgb(255, 50, 50)";
			} else if (dotsRefCollection[index]) {
				
				dotsRefCollection[index].style.background = "rgb(150, 150, 150)";
			}
		});
	};

	const allContainersLoadedIntoRefs = () => {
		return !!Object.values(refCollection).length && !Object.values(refCollection).filter(value => !value).length;
	};

	const setCounter = (prevCount => {
		if (prevCount === project.img.length - 1) {
			return 0;
		}
		return prevCount + 1;
	});

	useEffect(() => {
		let timer;
		if (!loadingImagesCount && allContainersLoadedIntoRefs() && project.img.length > 1) {
			setCreateDotts(true);
			colorActiveDot();
			timer = setTimeout(() => {
				setSliderCounter(prevCount => setCounter(prevCount));
			}, 7000);
		}
		return () => { if (timer) { clearTimeout(timer) } };
	}, [loadingImagesCount, refCollection]);

	useEffect(() => {
		if (allContainersLoadedIntoRefs()) {
			refCollection[sliderCounter].style.left = 0;
			refCollection[sliderCounter].style.opacity = 1;
			Object.keys(refCollection).forEach(refIndex => {
				if ((refIndex != sliderCounter) && refCollection[refIndex].style.left === "0px") {
					refCollection[refIndex].style.opacity = 0;
					refCollection[refIndex].style.left = "100%";
				}
			});
			colorActiveDot();
		}
	}, [sliderCounter]);
	
	if (!project) {
		return <Redirect to="/notFound" />;
	}

	return (
		<div className="project-container">	
			<div className="slider-container">
				{createImageSlider()}
				{!!loadingImagesCount && <div className="loader-container"><img src={loader} alt="loader" className="loader" /></div>}
			</div>
			{createDots && createSliderDotts()}
			<div className="project-description">
				<p>PROJEKAT - {project.projekat}</p>
				<p>VELIČINA - {project.velicina}m<sup>2</sup></p>
				<p>INVESTITOR - {project.investitor}</p>
				<p>LOKACIJA - {project.lokacija}</p>
				<p>GODINA - {project.godina}.</p>
			</div>
		</div>
	);
};

export default ProjekatPage;