import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/icons/logo.jpg";

import "./HomePage.css";


const HomePage = () => {
	return (
		<div className="home-page-container">
			<Link to="/profil" className="main-link"><img src={logo} alt="Arhitektura Produkt Main Logo" /></Link>
		</div>
	)
}

export default HomePage;