import React from "react";

import "./NotFoundPage.css";

const NotFoundPage = () => {
	return (
		<h2>404 Not Found</h2>
	);
};

export default NotFoundPage;