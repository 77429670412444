import React from "react";
import ProjectContainer from "./ProjectContainer"
import projectMapping from "./mappings/projectMapping";
import "./ProjektiPage.css";

const ProjektiPage = props => {
	const galeryImages = Object.keys(projectMapping).map(prop => {
		const propString = prop;
		prop = projectMapping[prop];
		prop.projectLocation = propString;
		return prop;
	}).sort((a, b) => {
		return b.godina - a.godina;
	}).map((project, index) => {
		return <ProjectContainer project={project} key={index} />
	});
	
	return (
		<div className="projects-container">
			{galeryImages}
		</div>
	);
};

export default ProjektiPage;